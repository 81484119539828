var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import Button from '@mui/material/Button';
var NutritionLabel = function (_a) {
    var nutrition = _a.nutrition, servingsCount = _a.servingsCount, servingSize = _a.servingSize, label = _a.label;
    var _b = useState(false), expanded = _b[0], setExpanded = _b[1];
    var marginOffsetStyle = { marginLeft: '10px', color: '#555' };
    var hrStyle = { fontSize: '6px', backgroundColor: '#777' };
    var divisor = 1;
    function divideAndFormat(num1, num2) {
        if (num2 === 0) {
            throw new Error("Division by zero is not allowed");
        }
        var result = num1 / num2;
        if (result >= 10) {
            return Math.floor(result).toString();
        }
        else {
            return result.toFixed(1);
        }
    }
    if (servingsCount && servingsCount <= 0) {
        servingsCount = 1;
    }
    if (servingsCount) {
        divisor = servingsCount;
    }
    var toggleExpanded = function () {
        setExpanded(!expanded);
    };
    return (_jsxs("div", __assign({ className: 'section' }, { children: [_jsx("div", __assign({ className: 'sectionLabelContainer' }, { children: _jsxs("h2", __assign({ className: 'sectionLabel' }, { children: ["Nutrition Facts ", label] })) })), _jsxs("div", __assign({ style: { display: 'flex', justifyContent: 'space-between', marginBottom: '10px' } }, { children: [_jsxs("div", __assign({ className: 'sectionColumnContainer' }, { children: [_jsx("strong", { children: "Calories" }), _jsx("span", { children: divideAndFormat(nutrition.totalCalories, divisor) })] })), _jsxs("div", __assign({ className: 'sectionColumnContainer' }, { children: [_jsx("strong", { children: "Carbs" }), _jsxs("span", { children: [divideAndFormat(nutrition.totalCarbohydrates, divisor), "g"] })] })), _jsxs("div", __assign({ className: 'sectionColumnContainer' }, { children: [_jsx("strong", { children: "Fat" }), _jsxs("span", { children: [divideAndFormat(nutrition.totalFat, divisor), "g"] })] })), _jsxs("div", __assign({ className: 'sectionColumnContainer' }, { children: [_jsx("strong", { children: "Protein" }), _jsxs("span", { children: [divideAndFormat(nutrition.protein, divisor), "g"] })] }))] })), _jsx(Button, __assign({ variant: "outlined", color: "primary", onClick: toggleExpanded, fullWidth: true }, { children: expanded ? 'Close' : 'See more...' })), _jsxs("div", __assign({ style: {
                    maxHeight: expanded ? '1000px' : '0',
                    overflow: 'hidden',
                    transition: 'max-height 0.5s ease-in-out'
                } }, { children: [_jsx("hr", { style: hrStyle }), _jsxs("div", { children: [_jsx("strong", { children: "Calories" }), " ", divideAndFormat(nutrition.totalCalories, divisor)] }), _jsx("hr", { style: hrStyle }), _jsxs("div", { children: [_jsx("strong", { children: "Total Fat" }), " ", divideAndFormat(nutrition.totalFat, divisor), "g"] }), _jsxs("div", __assign({ style: marginOffsetStyle }, { children: ["Saturated Fat ", divideAndFormat(nutrition.saturatedFat, divisor), "g"] })), _jsx("hr", { style: hrStyle }), _jsxs("div", { children: [_jsx("strong", { children: "Cholesterol" }), " ", divideAndFormat(nutrition.cholesterol, divisor), "mg"] }), _jsx("hr", { style: hrStyle }), _jsxs("div", { children: [_jsx("strong", { children: "Sodium" }), " ", divideAndFormat(nutrition.sodium, divisor), "mg"] }), _jsx("hr", { style: hrStyle }), _jsxs("div", { children: [_jsx("strong", { children: "Total Carbohydrates" }), " ", divideAndFormat(nutrition.totalCarbohydrates, divisor), "g"] }), _jsxs("div", __assign({ style: marginOffsetStyle }, { children: ["Dietary Fiber ", divideAndFormat(nutrition.dietaryFiber, divisor), "g"] })), _jsxs("div", __assign({ style: marginOffsetStyle }, { children: ["Sugars ", divideAndFormat(nutrition.sugars, divisor), "g"] })), _jsxs("div", __assign({ style: marginOffsetStyle }, { children: ["Added Sugars ", divideAndFormat(nutrition.addedSugars, divisor), "g"] })), _jsx("hr", { style: hrStyle }), _jsxs("div", { children: [_jsx("strong", { children: "Protein" }), " ", divideAndFormat(nutrition.protein, divisor), "g"] }), _jsx("hr", { style: hrStyle }), _jsxs("div", { children: [_jsx("strong", { children: "Potassium" }), " ", divideAndFormat(nutrition.potassium, divisor), "g"] }), _jsx("hr", { style: hrStyle }), _jsx("small", __assign({ style: { fontSize: 'smaller', color: '#777' } }, { children: "* nutritional values are estimates" }))] }))] })));
};
export default NutritionLabel;
