var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, Typography, Box } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTimeFilled';
import PieChartIcon from '@mui/icons-material/PieChart';
import { formatTime } from '../../helpers/formatTime';
var RecipeImageBackground = function (_a) {
    var recipe = _a.recipe, showDescription = _a.showDescription, setSelectedRecipe = _a.setSelectedRecipe;
    var handleRecipeClick = function (recipe) {
        if (setSelectedRecipe) {
            setSelectedRecipe(recipe);
        }
    };
    var validImageUrl = recipe.imageUrlList.length > 0 ? recipe.imageUrlList[0].lineItem : 'default.png';
    var layoutBackgroundColor = "rgba(250, 248, 252, 0.95)";
    var textShadowColor = '1px 1px 2px rgba(0, 0, 0, 0.1)';
    var textPadding = "4px 8px 4px 4px";
    var borderRadius = "8px";
    var cardContentBorderRadius = "0 0 " + borderRadius + " " + borderRadius;
    var totalTimeInMinutes = recipe.prepTimeInMinutes + recipe.cookTimeInMinutes;
    var renderTime = (recipe.cookTimeInMinutes + recipe.prepTimeInMinutes) > 0 ?
        _jsxs(Box, __assign({ display: "inline-flex", sx: { padding: textPadding }, alignItems: "center", mb: 1, bgcolor: layoutBackgroundColor, borderRadius: borderRadius, p: 0.5 }, { children: [_jsx(AccessTimeIcon, { color: "primary" }), _jsx(Typography, __assign({ variant: "body2", sx: { ml: 1, fontWeight: 'bold', textShadow: textShadowColor } }, { children: formatTime(totalTimeInMinutes) }))] })) : _jsx("div", {});
    var renderServings = (recipe.numberOfServings > 0) ?
        _jsxs(Box, __assign({ display: "inline-flex", sx: { padding: textPadding }, alignItems: "center", bgcolor: layoutBackgroundColor, borderRadius: borderRadius, p: 0.5 }, { children: [_jsx(PieChartIcon, { color: "primary" }), _jsxs(Typography, __assign({ variant: "body2", sx: { ml: 1, fontWeight: 'bold', textShadow: textShadowColor } }, { children: [recipe.numberOfServings, " ", recipe.numberOfServings === 1 ? 'serving' : 'servings'] }))] })) : _jsx("div", {});
    return (_jsx(Card, __assign({ onClick: function () { return handleRecipeClick(recipe); }, sx: {
            width: '100%',
            height: { xs: 200, sm: 300 },
            borderRadius: borderRadius,
            backgroundImage: "url(".concat(validImageUrl, ")"),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            transition: 'transform 0.3s ease',
            '&:hover': { transform: 'scale(1.05)' }
        } }, { children: _jsxs(Box, __assign({ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }, { children: [_jsxs(Box, __assign({ display: 'flex', flexDirection: "column", pl: 1, pt: 1, alignItems: "flex-start" }, { children: [renderTime, renderServings] })), _jsxs(CardContent, __assign({ sx: {
                        backgroundColor: layoutBackgroundColor,
                        borderRadius: cardContentBorderRadius,
                        padding: { xs: 1, sm: 2 },
                        height: { xs: '60px', sm: '80px', },
                        overflow: 'hidden',
                        '&:last-child': {
                            paddingBottom: '0px'
                        }
                    } }, { children: [_jsx(Typography, __assign({ variant: "h6", align: "center", sx: { fontSize: { xs: '1.1rem', sm: '1.25rem' } } }, { children: recipe.title })), showDescription && recipe.description && _jsxs(Typography, __assign({ variant: "body2", align: "center", sx: { fontSize: { xs: '0.8rem', sm: '0.9rem' } } }, { children: ["Description: ", recipe.description] }))] }))] })) })));
};
export default RecipeImageBackground;
