var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from "@mui/material";
import SyncIcon from '@mui/icons-material/Sync';
function LoadingText() {
    return (_jsx(Button, __assign({ variant: "contained", className: "loginbutton", startIcon: _jsx(SyncIcon, { className: 'loading-icon' }) }, { children: "Loading" })));
}
export default LoadingText;
