var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { trackTrace } from '../Telemetry/AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import './404.css';
var Server404 = function (_a) {
    var message = _a.message, image = _a.image, context = _a.context;
    useEffect(function () {
        trackTrace("404 Page", SeverityLevel.Warning, context !== null && context !== void 0 ? context : {});
    }, []);
    return (_jsxs("header", { children: [_jsx("div", __assign({ className: "notFoundContainer" }, { children: image && _jsx("img", { className: 'notFoundImage', src: image }) })), _jsx("div", __assign({ className: "notFoundContainer" }, { children: _jsx("h1", __assign({ className: "notFoundMessage" }, { children: message })) }))] }));
};
export default Server404;
