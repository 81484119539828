var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './Header.css';
function Header(props) {
    var getHeaderTitleLabel = function () {
        switch (location.pathname) {
            case '/about':
                return 'About';
            case '/profile':
                return 'Account';
            case '/recipes':
                return 'My Recipes';
            default:
                return 'Spice Packs';
        }
    };
    return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "mealGenie" }, { children: [_jsx("h1", { children: "MealGenie" }), _jsx("h3", { children: getHeaderTitleLabel() })] })), _jsx("div", __assign({ className: 'storeBanner animated-wipe-in' }, { children: _jsxs("a", __assign({ target: '_blank', href: 'https://shop.mealgenie.io/' }, { children: ["Shop spices now ", _jsx("span", { dangerouslySetInnerHTML: { __html: '-&gt;' } })] })) }))] }));
}
export default Header;
