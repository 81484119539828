import { jsx as _jsx } from "react/jsx-runtime";
import Layout from '../Layouts/Layout';
import { trackPageView } from '../Telemetry/AppInsights';
import { useEffect } from 'react';
import { Telemetry } from '../Telemetry/TelemtryEventNames';
import SupportComponent from '../CustomerSupport/SupportComponent';
var SupportPage = function () {
    useEffect(function () {
        trackPageView(Telemetry.PageView.Support);
    }, []);
    return (_jsx(Layout, { children: _jsx(SupportComponent, {}) }));
};
export default SupportPage;
