var cuisineTypes = [
    "",
    "American",
    "Brazilian",
    "British",
    "Cajun/Creole",
    "Caribbean",
    "Chinese",
    "Cuban",
    "Ethiopian",
    "European",
    "Filipino",
    "French",
    "German",
    "Greek",
    "Indian",
    "Indonesian",
    "Irish",
    "Italian",
    "Japanese",
    "Korean",
    "Lebanese",
    "Malaysian",
    "Mediterranean",
    "Mexican",
    "Middle Eastern",
    "Moroccan",
    "Peruvian",
    "Russian",
    "Spanish",
    "Thai",
    "Turkish",
    "Vietnamese"
];
export default cuisineTypes;
// Todo Future cusine type reference
var cuisineTypesMasterList = {
    African: [
        "Ethiopian",
        "Moroccan",
        "Nigerian",
        "South African"
    ],
    American: [
        "Cajun",
        "Creole",
        "Southern",
        "Tex-Mex",
        "New England",
        "Californian",
        "Southwestern",
        "Hawaiian",
        "Native American",
        "Soul Food"
    ],
    Asian: {
        Chinese: [
            "Cantonese",
            "Sichuan",
            "Shandong",
            "Fujian"
        ],
        Indian: [
            "North Indian",
            "South Indian",
            "Gujarati",
            "Bengali",
            "Punjabi"
        ],
        Indonesian: "Indonesian",
        Japanese: [
            "Sushi",
            "Ramen"
        ],
        Korean: "Korean",
        Malaysian: "Malaysian",
        Thai: "Thai",
        Vietnamese: "Vietnamese",
        Filipino: "Filipino",
        Burmese: "Burmese",
        Cambodian: "Khmer",
        Lao: "Lao"
    },
    European: {
        EasternEuropean: [
            "Russian",
            "Polish",
            "Hungarian",
            "Czech",
            "Romanian"
        ],
        British: "British",
        French: "French",
        German: "German",
        Greek: "Greek",
        Irish: "Irish",
        Italian: [
            "Sicilian",
            "Tuscan",
            "Neapolitan"
        ],
        Portuguese: "Portuguese",
        Spanish: [
            "Catalan",
            "Basque"
        ],
        Scandinavian: [
            "Danish",
            "Finnish",
            "Norwegian",
            "Swedish"
        ],
        Dutch: "Dutch",
        Swiss: "Swiss",
        Belgian: "Belgian",
        Austrian: "Austrian"
    },
    LatinAmerican: [
        "Argentine",
        "Brazilian",
        "Chilean",
        "Colombian",
        "Cuban",
        "Mexican",
        "Peruvian",
        "Puerto Rican",
        "Venezuelan"
    ],
    MiddleEastern: [
        "Arabic",
        "Turkish",
        "Persian",
        "Israeli",
        "Lebanese"
    ],
    Oceanian: [
        "Australian",
        "New Zealand"
    ],
    DietaryAlternative: [
        "Vegetarian",
        "Vegan",
        "Pescatarian",
        "Gluten-free",
        "Raw Food",
        "Paleo",
        "Keto",
        "Low-carb",
        "Halal",
        "Kosher"
    ]
};
