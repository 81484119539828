export var formatTime = function (totalMinutes) {
    var days = Math.floor(totalMinutes / (24 * 60));
    var hours = Math.floor((totalMinutes % (24 * 60)) / 60);
    var minutes = totalMinutes % 60;
    var formattedTime = '';
    if (days > 0)
        formattedTime += "".concat(days, " ").concat(days === 1 ? 'day' : 'days', " ");
    if (hours > 0)
        formattedTime += "".concat(hours, " ").concat(hours === 1 ? 'hour' : 'hours', " ");
    if (minutes > 0)
        formattedTime += "".concat(minutes, " ").concat(minutes === 1 ? 'minute' : 'minutes');
    return formattedTime.trim();
};
