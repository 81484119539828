import { jsx as _jsx } from "react/jsx-runtime";
import { HOST_Server } from '../../config/config.dev';
import Layout from '../Layouts/Layout';
import { trackPageView } from '../Telemetry/AppInsights';
import { useEffect } from 'react';
import { Telemetry } from '../Telemetry/TelemtryEventNames';
var RedirectPage = function (props) {
    var relativePath = props.relativePath;
    useEffect(function () {
        trackPageView(Telemetry.PageView.RedirectPage);
        window.location.href = relativePath ? HOST_Server + relativePath : HOST_Server;
    }, []);
    return (_jsx(Layout, {}));
};
export default RedirectPage;
