var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import './EditableInputStyle.css';
import '../AutoSuggestions/AutoSuggest.css';
import { TextField } from "@mui/material";
import validateInputValue from "./inputValidators";
var EditableTextInput = forwardRef(function (_a, ref) {
    var model = _a.model, field = _a.field, label = _a.label, onUpdateModel = _a.onUpdateModel, fieldName = _a.fieldName, isEditing = _a.isEditing, isMultiLine = _a.isMultiLine, numberMultiLineRows = _a.numberMultiLineRows, inputProperties = _a.inputProperties, autoSuggestData = _a.autoSuggestData, disableBrowserAutoComplete = _a.disableBrowserAutoComplete, placeholder = _a.placeholder, noUnderline = _a.noUnderline, isRequired = _a.isRequired;
    var multiLineRows = numberMultiLineRows ? numberMultiLineRows : 4;
    var _b = useState(field), editableField = _b[0], setEditableField = _b[1];
    var _c = useState([]), suggestions = _c[0], setSuggestions = _c[1];
    var _d = useState(''), error = _d[0], setError = _d[1]; // State to hold the error message
    var _e = useState(false), isFieldError = _e[0], setIsFieldError = _e[1]; // State to hold the error status
    var _f = useState(null), selectedSuggestionIndex = _f[0], setSelectedSuggestionIndex = _f[1];
    var suggestionsRef = useRef(null);
    var inputRef = useRef(null);
    // Use useImperativeHandle to expose the value
    useImperativeHandle(ref, function () { return ({
        getValue: function () { return editableField; },
        getInputRef: function () { return inputRef === null || inputRef === void 0 ? void 0 : inputRef.current; },
    }); });
    useEffect(function () {
        // Reset the selected suggestion index when the suggestions list changes
        setSelectedSuggestionIndex(null);
    }, [suggestions]);
    var handleKeyDown = function (event) {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedSuggestionIndex(function (prevIndex) { return (prevIndex !== null ? Math.min(prevIndex + 1, suggestions.length - 1) : 0); });
        }
        else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedSuggestionIndex(function (prevIndex) { return (prevIndex !== null ? Math.max(prevIndex - 1, 0) : suggestions.length - 1); });
        }
        else if (event.key === 'Enter') {
            if (selectedSuggestionIndex !== null) {
                handleSuggestionClick(suggestions[selectedSuggestionIndex]);
            }
            else {
                handleEditSubmit();
            }
        }
    };
    var showSuggestions = function (input, maxSuggestions) {
        if (autoSuggestData && input) {
            var filteredSuggestions = autoSuggestData
                .filter(function (size) { return size.toLowerCase().startsWith(input.toLowerCase()); })
                .slice(0, maxSuggestions); // Limit the number of suggestions using slice
            setSuggestions(filteredSuggestions);
        }
        else {
            setSuggestions([]);
        }
    };
    var handleFieldChange = function (event) {
        var fieldValue = event.target.type === 'number' ? parseFloat(event.target.value) : event.target.value.trim();
        if (event.target.value.endsWith(' ')) {
            fieldValue += ' ';
        }
        setEditableField(fieldValue);
        validation(fieldValue, event.target.type, inputProperties, isRequired);
        showSuggestions(event.target.value, 3);
    };
    var validation = function (fieldValue, type, inputProperties, isRequired) {
        if (isRequired === void 0) { isRequired = false; }
        var validationError = validateInputValue(fieldValue, type, inputProperties, isRequired);
        if (validationError) {
            setError(validationError);
            setIsFieldError(true);
        }
        else {
            setError('');
            setIsFieldError(false);
        }
        return validationError;
    };
    var handleEditSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var updatedModel;
        var _a;
        return __generator(this, function (_b) {
            setError('');
            setIsFieldError(false);
            validation(editableField, typeof (editableField), inputProperties, isRequired);
            if (isFieldError) {
                // Do not save when there is an error    
                return [2 /*return*/];
            }
            if (editableField !== field) {
                updatedModel = __assign(__assign({}, model), (_a = {}, _a[fieldName] = editableField, _a));
                onUpdateModel(updatedModel);
            }
            return [2 /*return*/];
        });
    }); };
    var handleSuggestionClick = function (suggestion) {
        setEditableField(suggestion);
        setSuggestions([]);
    };
    var editableInput = isEditing ? (_jsxs("div", __assign({ className: "suggestions-container" }, { children: [_jsx(TextField, { fullWidth: true, label: label, variant: "standard", type: typeof field === 'number' ? 'number' : 'text', value: editableField, onChange: handleFieldChange, onBlur: handleEditSubmit, autoFocus: true, multiline: isMultiLine, maxRows: multiLineRows, inputProps: inputProperties, error: !!error, helperText: error, onKeyDown: handleKeyDown, autoComplete: disableBrowserAutoComplete ? "off" : "on", placeholder: placeholder ? placeholder : "", inputRef: inputRef }), suggestions.length > 0 && (_jsx("div", __assign({ id: "suggestions", ref: suggestionsRef }, { children: suggestions.map(function (suggestion, index) { return (_jsx("div", __assign({ onPointerDown: function () {
                        setSelectedSuggestionIndex(index);
                        handleSuggestionClick(suggestion);
                    }, onMouseOver: function () {
                        setSelectedSuggestionIndex(index);
                    }, onTouchEnd: function () {
                        setSelectedSuggestionIndex(index);
                        handleSuggestionClick(suggestion);
                    }, className: index === selectedSuggestionIndex ? 'selected-suggestion' : '' }, { children: suggestion }), suggestion)); }) })))] }))) : (_jsx("div", { children: _jsx(TextField, { fullWidth: true, InputProps: {
                readOnly: true,
                classes: noUnderline && isEditing === false ? {
                    underline: 'no-underline',
                } : {},
            }, label: label, variant: "standard", multiline: isMultiLine, maxRows: multiLineRows, type: typeof field === 'number' ? 'number' : 'text', value: field }) }));
    return _jsx("div", __assign({ className: "listItemSpacing" }, { children: editableInput }));
});
// When you use forwardRef in React, you create a component without an explicit name.
// To fix this error, you should assign a display name to the EditableTextInput component after you've defined it.
EditableTextInput.displayName = 'EditableTextInput';
export default EditableTextInput;
