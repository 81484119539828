import { jsx as _jsx } from "react/jsx-runtime";
import Layout from '../Layouts/Layout';
import UserRecipes from '../Recipes/UserRecipes';
import { trackPageView } from '../Telemetry/AppInsights';
import { useEffect } from 'react';
import { Telemetry } from '../Telemetry/TelemtryEventNames';
function Recipes() {
    useEffect(function () {
        trackPageView(Telemetry.PageView.UserRecipes);
    }, []);
    return (_jsx(Layout, { children: _jsx(UserRecipes, {}) }));
}
export default Recipes;
