import { createTheme } from '@mui/material/styles';
/// Note This color is the 'grey' version of the secondary color #cfc2e1.
// # faf8fc 
var theme = createTheme({
    palette: {
        primary: {
            main: "#5a248c", // Change this value to your desired primary color
        },
        secondary: {
            main: "#cfc2e1", // Change this value to your desired secondary color
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    // Your button styles here
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    padding: '12px 24px',
                    borderRadius: '8px',
                },
                // Text buttons
                textPrimary: {
                // Your styles for text primary buttons
                },
                textSecondary: {
                // Your styles for text secondary buttons
                },
                // Outlined buttons
                outlinedPrimary: {
                // borderColor: '#4A3DAB',
                // color: '#4A3DAB',
                // '&:hover': {
                //   borderColor: '#C0B9F1',
                //   color: '#C0B9F1',
                //   backgroundColor: 'rgba(21, 101, 192, 0.1)',
                // },
                },
                outlinedSecondary: {
                // Your styles for outlined secondary buttons
                },
                // Contained buttons
                containedPrimary: {
                // backgroundColor: '#4A3DAB',
                // color: '#fff',
                // '&:hover': {
                //   backgroundColor: '#C0B9F1',
                // },
                },
                containedSecondary: {
                // Your styles for contained secondary buttons
                }
            },
        },
    },
});
export default theme;
