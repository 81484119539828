import { jsx as _jsx } from "react/jsx-runtime";
import Layout from '../Layouts/Layout';
import { trackPageView } from '../Telemetry/AppInsights';
import { useEffect } from 'react';
import AboutComponent from '../About/AboutComponent';
import { Telemetry } from '../Telemetry/TelemtryEventNames';
var AboutPage = function () {
    useEffect(function () {
        trackPageView(Telemetry.PageView.About);
    }, []);
    return (_jsx(Layout, { children: _jsx(AboutComponent, {}) }));
};
export default AboutPage;
