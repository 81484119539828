export var placeHolderTitle = "Edit your recipe...";
var newRecipeData = {
    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    "shortCode": "",
    "userId": "userid",
    "title": "",
    "description": "",
    "ingredientList": [],
    "directionList": [],
    "tagList": [],
    "noteList": [],
    "imageUrlList": [],
    "prepTimeInMinutes": 0,
    "cookTimeInMinutes": 0,
    "numberOfServings": 1,
    "servingSize": "",
    "sourceUrl": "",
    "cuisineType": "",
    "mealType": "",
    "isEnabled": true,
    "isPublic": false,
    "isNewRecipe": true,
    "createdTime": "2023-03-21T20:59:21.964Z",
    "modifiedTime": "2023-03-21T20:59:21.964Z"
};
export var getNewRecipeData = function () {
    newRecipeData.createdTime = new Date().toISOString();
    newRecipeData.modifiedTime = newRecipeData.createdTime;
    return newRecipeData;
};
