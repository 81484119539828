var validateInputValue = function (value, type, inputProperties, isRequired) {
    if (isRequired === void 0) { isRequired = false; }
    if (type === 'number' && inputProperties) {
        var val = value;
        if (val >= inputProperties.max) {
            return "Oops! The value should be less than ".concat(inputProperties.max, ". Please try a smaller number.");
        }
        if (val < inputProperties.min) {
            return "Oops! The value should be at least ".concat(inputProperties.min, ". Please try a larger number.");
        }
    }
    if ((type === 'text' || type === 'string') && inputProperties) {
        var val = value;
        if (isRequired && val.length < 1) {
            return "Oops! This is required.";
        }
        if (val.length < inputProperties.minLength) {
            return "Oops! This is too short.";
        }
        if (val.length >= inputProperties.maxLength) {
            return "Oops! This is too long. Please shorten it.";
        }
    }
    return '';
};
export default validateInputValue;
