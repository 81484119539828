var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './styles/Logo.css';
import './styles/Grid.css';
import Layout from '../Layouts/Layout';
import { trackPageView, trackTrace } from '../Telemetry/AppInsights';
import { useEffect, useState } from 'react';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import { HOST_Server, PATH_APICURRENTVERSION, PATH_PUBLIC_SpicePack, SHOPIFY_CUSTOMPRODUCT, SHOPIFY_DOMAIN, SHOPIFY_STOREFRONTTOKEN } from '../../config/config.dev';
import RecipeCard from '../Recipes/RecipeCardImageBackground';
import RecipeView from '../Recipes/RecipeView';
import { Telemetry } from '../Telemetry/TelemtryEventNames';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import ShopifyBuyButton from '../Shopify/BuyButton';
function DiscoverPage() {
    var _a = useState([]), recipes = _a[0], setRecipes = _a[1];
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(null), selectedRecipe = _c[0], setSelectedRecipe = _c[1];
    useEffect(function () {
        trackPageView(Telemetry.PageView.SpicePackHome);
    }, []);
    useEffect(function () {
        function getUserRecipes() {
            return __awaiter(this, void 0, void 0, function () {
                var response, metadata, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, , 4]);
                            return [4 /*yield*/, fetch("".concat(HOST_Server).concat(PATH_APICURRENTVERSION).concat(PATH_PUBLIC_SpicePack))];
                        case 1:
                            response = _a.sent();
                            return [4 /*yield*/, response.json()];
                        case 2:
                            metadata = _a.sent();
                            setRecipes(metadata);
                            setIsLoading(false);
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            console.error(error_1);
                            setIsLoading(false); // Set loading to false even if there's an error
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
        getUserRecipes();
    }, []);
    if (selectedRecipe) {
        return (_jsx(Layout, { children: _jsx(RecipeView, { recipe: selectedRecipe, setSelectedRecipe: setSelectedRecipe, isEditable: false, isSpicePack: true }) }));
    }
    if (isLoading) {
        return (_jsx(Layout, { children: _jsx(LoadingScreen, {}) }));
    }
    if (!isLoading && recipes && (recipes === null || recipes === void 0 ? void 0 : recipes.length) < 1) {
        trackTrace(Telemetry.PageView.SpicePackHome + "No recipes found when data was expected", SeverityLevel.Critical);
        return (_jsx(Layout, { children: _jsx(LoadingScreen, { children: _jsx("div", {}) }) }));
    }
    var buyButton = _jsx(ShopifyBuyButton, { domain: SHOPIFY_DOMAIN, storefrontAccessToken: SHOPIFY_STOREFRONTTOKEN, productId: SHOPIFY_CUSTOMPRODUCT, customProductId: "homepage-cart-id", customProductName: "homepage-cart-name", dontRenderUX: true });
    return (_jsxs(Layout, { children: [_jsx("div", __assign({ className: "recipeGrid" }, { children: recipes.map(function (recipe) { return (_jsx(RecipeCard, { recipe: recipe, setSelectedRecipe: setSelectedRecipe }, recipe.id)); }) })), buyButton] }));
}
export default DiscoverPage;
