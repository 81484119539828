var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import AppRouter from './AppRouter';
import { ThemeProvider } from '@mui/material/styles';
import themeManager from '../Theme/themeManager';
import { Auth0Provider } from "@auth0/auth0-react";
import { CONFIG_Auth0Audience, CONFIG_Auth0ClientId, CONFIG_Auth0Domain } from '../../config/config.dev';
function App() {
    return (_jsx(Auth0Provider, __assign({ domain: CONFIG_Auth0Domain, clientId: CONFIG_Auth0ClientId, authorizationParams: {
            redirect_uri: window.location.origin,
            audience: CONFIG_Auth0Audience
        } }, { children: _jsx(ThemeProvider, __assign({ theme: themeManager }, { children: _jsx(AppRouter, {}) })) })));
}
export default App;
