var SOFTWARE_CLIENT_VERSION = "05.06.2024";
// Default shared settings
var CONFIG_Auth0ClientId = process.env.AUTH0_ClientId;
var CONFIG_Auth0Domain = process.env.AUTH0_Domain;
var CONFIG_Auth0Audience = process.env.AUTH0_Audience;
// API Server Settings
var HOST_Server = process.env.FRONTDOOR_PROTOCOL + process.env.FRONTDOOR_ENDPOINTHOST;
var domain = typeof window !== 'undefined' ? window.location.hostname : undefined;
// Check if the domain starts with 'www.' to ensure cross domain support for 
// www.mealgenie.io and mealgenie.io
if (domain && domain.startsWith('www.')) {
    HOST_Server = process.env.FRONTDOOR_PROTOCOL + "www." + process.env.FRONTDOOR_ENDPOINTHOST;
}
var PATH_APICURRENTVERSION = "api/v1/";
var PATH_Recipes = "Recipe";
var PATH_Account = "Account";
var PATH_Support = "CustomerSupport";
var PATH_Account_AddTerm = "addterm";
var PATH_Account_NewAccount = "newAccount";
var PATH_PUBLIC_SharedRecipes = "PublicSharedRecipe";
var PATH_PUBLIC_SpicePack = "SpicePack";
var PATH_PUBLIC_SharedRecipes_GenerateHash = PATH_PUBLIC_SharedRecipes + "/" + "getshareid";
var PATH_PUBLIC_SpicePack_Transaction = PATH_PUBLIC_SpicePack + "/" + "transact";
// Public Front End Server Settings (in the API layer)
var PATH_FrontEnd_SharedRecipe = "share";
var PATH_FrontEnd_SharedSpicePack = "spicepack";
var PARAM_FrontEnd_ShareId = "s";
var CONNECTION_APPINIGHTS = process.env.CONNECTION_STRING_APPINIGHTS;
var SHOPIFY_DOMAIN = "mealgenieshop.myshopify.com";
var SHOPIFY_STOREFRONTTOKEN = "7d3129cc0f0c8891a5de7821eff90e80";
var SHOPIFY_CUSTOMPRODUCT = "11719176487072";
var SHOPIFY_PRODUCTDEEPLINKPREFIXURL = "https://mealgenieshop.myshopify.com/products/"; // append product ID
export { CONFIG_Auth0ClientId, CONFIG_Auth0Domain, CONFIG_Auth0Audience, PATH_APICURRENTVERSION, HOST_Server, PATH_Recipes, PATH_Support, PATH_Account, PATH_Account_AddTerm, PATH_Account_NewAccount, PATH_PUBLIC_SharedRecipes, PATH_PUBLIC_SharedRecipes_GenerateHash, PATH_PUBLIC_SpicePack, PATH_PUBLIC_SpicePack_Transaction, PATH_FrontEnd_SharedRecipe, PATH_FrontEnd_SharedSpicePack, PARAM_FrontEnd_ShareId, SOFTWARE_CLIENT_VERSION, CONNECTION_APPINIGHTS, SHOPIFY_DOMAIN, SHOPIFY_STOREFRONTTOKEN, SHOPIFY_CUSTOMPRODUCT, SHOPIFY_PRODUCTDEEPLINKPREFIXURL };
