var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth0 } from "@auth0/auth0-react";
import '../Layouts/Layout.css';
import { Button } from "@mui/material";
var LogoutButton = function () {
    var logout = useAuth0().logout;
    // Note: When using navbar in layout, if the below item was an <a> tag then it would render by default.
    // Setting link to="null" causes nothing to happen and the onclick event fires"
    // See LoginButton where <a> is kept intentionally to promote login
    return (_jsx(Button, __assign({ variant: "contained", className: "logoutbutton", onClick: function () { return logout({ logoutParams: { returnTo: window.location.origin } }); } }, { children: "Log Out" })));
};
export default LogoutButton;
