var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import LoadingText from '../LoadingScreen/LoadingText';
import { Avatar, Box, Typography, Paper } from '@mui/material';
import LogoutButton from './LogoutButton';
var Auth0Profile = function () {
    var _a = useAuth0(), user = _a.user, isAuthenticated = _a.isAuthenticated, isLoading = _a.isLoading, logout = _a.logout;
    if (isLoading) {
        return _jsx(LoadingText, {});
    }
    if (!isAuthenticated) {
        return (_jsx(Box, __assign({ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }, { children: _jsx(Typography, __assign({ variant: "h6" }, { children: "Unauthenticated" })) })));
    }
    return (isAuthenticated && (_jsx(Box, __assign({ display: "flex", flexDirection: "column", alignItems: "center", p: 3 }, { children: _jsxs(Paper, __assign({ elevation: 3, style: { borderRadius: '15px', padding: '20px', width: '300px' } }, { children: [_jsx(Typography, __assign({ variant: "h4", align: "center", gutterBottom: true }, { children: "Profile" })), _jsx(Avatar, { src: user === null || user === void 0 ? void 0 : user.picture, alt: user === null || user === void 0 ? void 0 : user.name, style: { width: '100px', height: '100px', margin: '0 auto' } }), _jsx(Typography, __assign({ variant: "h6", align: "center", gutterBottom: true }, { children: user === null || user === void 0 ? void 0 : user.name })), _jsx(Typography, __assign({ variant: "body1", align: "center" }, { children: user === null || user === void 0 ? void 0 : user.email })), _jsx(Box, __assign({ mt: 2 }, { children: _jsx(LogoutButton, {}) }))] })) }))));
};
export default Auth0Profile;
