var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { HOST_Server, PATH_APICURRENTVERSION, PATH_Recipes } from '../../config/config.dev';
import { getNewRecipeData } from './Data/NewRecipeData';
import RecipeView from './RecipeView';
import './EditableInputStyle.css';
import { Button, FormControl, Input, InputAdornment, InputLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import '../Navigation/ContextButtons.css';
import './UserRecipes.css';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import RecipeCard from './RecipeCardImageBackground';
import { useAuth0 } from '@auth0/auth0-react';
import { trackException } from '../Telemetry/AppInsights';
import Fuse from 'fuse.js';
import '../Search/Search.css';
function UserRecipes() {
    var _this = this;
    var user = useAuth0().user;
    var _a = useState([]), recipes = _a[0], setRecipes = _a[1];
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _c = useState(true), reloadData = _c[0], setReloadData = _c[1];
    //////////////////////////////////////////////////////////////////////////////////////
    /// Begin Search Code using Fuse.js
    var _d = useState(''), searchTerm = _d[0], setSearchTerm = _d[1];
    var handleSearchChange = function (event) {
        setSearchTerm(event.target.value);
    };
    // Configure Fuse.js options
    var fuseOptions = {
        keys: ['title'],
        includeScore: true, // Include search scores for ranking
    };
    // Initialize Fuse.js instance with the provided models and options
    var fuse = new Fuse(recipes, fuseOptions);
    // Perform search and get filtered models
    var searchResults = fuse.search(searchTerm);
    var filteredRecipes = searchTerm ? searchResults.map(function (result) { return result.item; }) : recipes;
    var searchInput = _jsx("div", __assign({ id: "recipe-search", className: "searchContainer" }, { children: _jsxs(FormControl, __assign({ className: "searchFormWidth", variant: "standard" }, { children: [_jsx(InputLabel, __assign({ htmlFor: "standard-adornment-password" }, { children: "Search" })), _jsx(Input, { id: "standard-basic", type: "text", placeholder: "Search your recipes", value: searchTerm, onChange: handleSearchChange, endAdornment: _jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(SearchIcon, {}) })) })] })) }));
    //////////////////////////////////////////////////////////////////////////////////////
    /// End Search Code using Fuse.js
    // Manages the scroll position of the Users Recipes Page
    var _e = useState(0), scrollPosition = _e[0], setScrollPosition = _e[1];
    // Manages the scroll position of the Recipe View Page
    var _f = useState({}), recipeScrollPositions = _f[0], setRecipeScrollPositions = _f[1];
    // Determines if the RecipeView should open in edit mode
    var _g = useState(false), openInEditMode = _g[0], setOpenInEditMode = _g[1];
    var _h = useState(null), selectedRecipe = _h[0], setSelectedRecipe = _h[1];
    // TODO: 
    // UX Bug: Scrolling snapping seems to have an issue IF the user scroll beneath the "description" box.
    //   The numbers work out correct, but if you go from Users page -> Recipe page (scroll down below description) -> Go back to Users page -> Go back to Recipe you just were on to see what I mean.
    //    Use creamy mushroom soup as example.
    //   Use logging statments below to debug/see/ 
    useEffect(function () {
        if (selectedRecipe) {
            //console.log("Recipe Selected Scrolling To" + recipeScrollPositions[selectedRecipe.id] )
            window.scrollTo(0, recipeScrollPositions[selectedRecipe.id] || 0);
        }
        else {
            //console.log("UserPage Scrolling To" + scrollPosition )
            window.scrollTo(0, scrollPosition);
        }
    }, [selectedRecipe]);
    useEffect(function () {
        function getUserRecipes() {
            return __awaiter(this, void 0, void 0, function () {
                var accessToken, error_1, ex;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 4, , 5]);
                            return [4 /*yield*/, getAccessTokenSilently()];
                        case 1:
                            accessToken = _a.sent();
                            if (!accessToken) return [3 /*break*/, 3];
                            return [4 /*yield*/, fetch("".concat(HOST_Server).concat(PATH_APICURRENTVERSION).concat(PATH_Recipes), {
                                    headers: {
                                        Authorization: "Bearer ".concat(accessToken),
                                    },
                                }).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                                    var metadata;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, response.json()];
                                            case 1:
                                                metadata = _a.sent();
                                                setRecipes(metadata);
                                                setIsLoading(false);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); })];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3: return [3 /*break*/, 5];
                        case 4:
                            error_1 = _a.sent();
                            ex = error_1;
                            console.error(error_1);
                            trackException(ex);
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        }
        if (reloadData) {
            getUserRecipes();
            setReloadData(false);
        }
    }, [selectedRecipe]);
    var handleRecipeClick = function (recipe) {
        var _a;
        if (selectedRecipe) {
            //console.log("Recipe Selected Scrolling To" + window.scrollY )
            setRecipeScrollPositions(__assign(__assign({}, recipeScrollPositions), (_a = {}, _a[selectedRecipe.id] = window.scrollY, _a)));
        }
        else {
            //console.log("UserPage Scrolling To" + window.scrollY )
            setScrollPosition(window.scrollY);
        }
        setSelectedRecipe(recipe);
    };
    // todo move to data folder as helper
    var handleAddRecipe = function () { return __awaiter(_this, void 0, void 0, function () {
        var accessToken, newRecipe, newRecipeResponse, newRecipe_1, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 6, , 7]);
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 1:
                    accessToken = _a.sent();
                    setOpenInEditMode(true); // Set isEditMode to true when adding a new recipe
                    newRecipe = getNewRecipeData();
                    return [4 /*yield*/, fetch("".concat(HOST_Server).concat(PATH_APICURRENTVERSION).concat(PATH_Recipes), {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: "Bearer ".concat(accessToken),
                            },
                            // Todo generate useful valid starting data
                            // Or consider creating the new recipe, but don't save it until the user edits it? 
                            // This would mean up would be replace instead of insert.
                            body: JSON.stringify(newRecipe),
                        })];
                case 2:
                    newRecipeResponse = _a.sent();
                    if (!newRecipeResponse.ok) return [3 /*break*/, 4];
                    return [4 /*yield*/, newRecipeResponse.json()];
                case 3:
                    newRecipe_1 = _a.sent();
                    setRecipes(__spreadArray(__spreadArray([], recipes, true), [newRecipe_1], false));
                    handleRecipeClick(newRecipe_1);
                    return [3 /*break*/, 5];
                case 4: throw new Error('Failed to create new recipe');
                case 5: return [3 /*break*/, 7];
                case 6:
                    error_2 = _a.sent();
                    console.error(error_2);
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var resetEditMode = function () {
        setOpenInEditMode(false);
    };
    var onRecipeClose = function () {
        setReloadData(true);
    };
    var buttons = [
        { id: "AddRecipeButton", text: "Add Recipe", onClick: handleAddRecipe, startIcon: _jsx(AddOutlinedIcon, {}) },
    ];
    // Note: Uses filtered recipes from search.
    var recipeList = _jsx("div", __assign({ className: "recipeGrid" }, { children: filteredRecipes.map(function (recipe, index) { return (_jsx(RecipeCard, { recipe: recipe, setSelectedRecipe: handleRecipeClick }, recipe.id)); }) }));
    if (selectedRecipe) {
        // Render the RecipeView component if a recipe is selected 
        return _jsx(RecipeView, { recipe: selectedRecipe, setSelectedRecipe: handleRecipeClick, isEditable: true, openInEditMode: openInEditMode, resetEditMode: resetEditMode, reloadOnClose: onRecipeClose });
    }
    else if (isLoading) {
        return _jsx(LoadingScreen, {});
    }
    else {
        // Render the list of recipes
        return (_jsxs("div", { children: [searchInput, recipeList, _jsx("div", __assign({ className: 'contextButtonMenu' }, { children: buttons.map(function (button, index) { return (_jsx(Button, __assign({ variant: "outlined", className: "buttonPadding", startIcon: button.startIcon, onClick: button.onClick }, { children: button.text }), button.id)); }) }))] }));
    }
}
export default UserRecipes;
