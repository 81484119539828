import { HOST_Server, PATH_FrontEnd_SharedRecipe, PARAM_FrontEnd_ShareId, PATH_FrontEnd_SharedSpicePack } from '../config/config.dev';
import { decode, encode } from './base64Tools';
export var buildShareUrl = function (encoded, isSpicePack) {
    var path = isSpicePack ? PATH_FrontEnd_SharedSpicePack : PATH_FrontEnd_SharedRecipe;
    var query = "?".concat(PARAM_FrontEnd_ShareId, "=").concat(encoded);
    var url = "".concat(HOST_Server).concat(path).concat(query);
    return url;
};
export var buildShareUrlEncode = function (shareId, recipeId) {
    var combined = "".concat(shareId, ";").concat(recipeId);
    var encoded = encode(combined);
    return encoded;
};
export var getMetaFromShareUrl = function (shareCode) {
    var decoded = decode(shareCode);
    var items = decoded.split(";");
    return {
        "uid": items[0],
        "rid": items[1]
    };
};
