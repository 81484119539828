var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { MenuItem, Select, FormControl, InputLabel, TextField } from "@mui/material";
import "./EditableInputStyle.css";
var EditableDropdownInput = function (_a) {
    var model = _a.model, field = _a.field, label = _a.label, onUpdateModel = _a.onUpdateModel, fieldName = _a.fieldName, isEditing = _a.isEditing, menuItems = _a.menuItems, renderReadOnlyAsTextField = _a.renderReadOnlyAsTextField;
    var _b = useState(field), editableField = _b[0], setEditableField = _b[1];
    var _c = useState(false), isFieldEditable = _c[0], setIsFieldEditable = _c[1];
    var handleFieldClick = function () {
        setIsFieldEditable(true);
    };
    var handleFieldChange = function (event) {
        var fieldValue = event.target.value;
        setEditableField(fieldValue);
    };
    var handleEditSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var updatedModel;
        var _a;
        return __generator(this, function (_b) {
            setIsFieldEditable(false);
            // todo add try catch    
            if (editableField !== field) {
                updatedModel = __assign(__assign({}, model), (_a = {}, _a[fieldName] = editableField, _a));
                onUpdateModel(updatedModel);
            }
            return [2 /*return*/];
        });
    }); };
    var editableInput = isEditing && isFieldEditable ? (_jsxs(FormControl, __assign({ className: "listItemSpacing" }, { children: [_jsx(InputLabel, { children: label }), _jsx(Select, __assign({ label: label, value: editableField, onChange: handleFieldChange, onBlur: handleEditSubmit, autoFocus: true }, { children: menuItems.map(function (item, index) { return (_jsx(MenuItem, __assign({ value: item }, { children: (item === "") ? "None" : item }), index)); }) }))] }))) : (_jsxs("div", __assign({ onClick: handleFieldClick }, { children: [renderReadOnlyAsTextField && _jsx(TextField, { id: "outlined-basic", label: label, variant: "outlined", value: field, InputProps: {
                    readOnly: true,
                } }), !renderReadOnlyAsTextField &&
                _jsxs(FormControl, __assign({ className: "listItemSpacing" }, { children: [_jsx(InputLabel, { children: label }), _jsx(Select, __assign({ label: label, value: field, inputProps: {
                                readOnly: true,
                            } }, { children: menuItems.map(function (item, index) { return (_jsx(MenuItem, __assign({ value: item }, { children: (item === "") ? "None" : item }), index)); }) }))] }))] })));
    return _jsx("div", __assign({ className: "listItemSpacing" }, { children: editableInput }));
};
export default EditableDropdownInput;
