var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './EditableInputStyle.css';
var EditableMultiLineListInput = function (_a) {
    var _b;
    var model = _a.model, onUpdateModel = _a.onUpdateModel, label = _a.label, property = _a.property, isEditing = _a.isEditing, isMultiLine = _a.isMultiLine, numberMultiLineRows = _a.numberMultiLineRows, inputProperties = _a.inputProperties, maxListItems = _a.maxListItems, EnableGrouping = _a.EnableGrouping, isSpicePack = _a.isSpicePack, renderLinks = _a.renderLinks;
    var multiLineRows = numberMultiLineRows ? numberMultiLineRows : 4;
    var _c = useState(''), multilineEditValue = _c[0], setMultilineEditValue = _c[1];
    useEffect(function () {
        if (isEditing) {
            var itemsString = model[property].map(function (item) { return item.lineItem; }).join('\n');
            setMultilineEditValue(itemsString);
        }
    }, [isEditing, model, property]);
    var handleMultilineChange = function (event) {
        setMultilineEditValue(event.target.value);
    };
    var handleMultilineSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var itemsArray;
        return __generator(this, function (_a) {
            itemsArray = multilineEditValue.split('\n').map(function (line) { return ({
                lineItem: line,
                groupId: '',
                isSpice: false, // Default value or any logic you might want
            }); });
            model[property] = itemsArray;
            onUpdateModel(model);
            return [2 /*return*/];
        });
    }); };
    var groupedItems = EnableGrouping
        ? model[property].reduce(function (acc, item) {
            if (!acc[item.groupId]) {
                acc[item.groupId] = [];
            }
            acc[item.groupId].push(item);
            return acc;
        }, {})
        : { default: model[property] };
    // Add spice pack line item
    // todo: move to server side logic
    var spicePackIngredient = isSpicePack ? model.title + " Spice Pack" : "";
    if (isSpicePack && spicePackIngredient.length > 0) {
        var spicePack_1 = {
            lineItem: spicePackIngredient,
            isSpice: false,
            groupId: "",
            isLink: true
        };
        var key = (_b = Object.keys(groupedItems)[0]) !== null && _b !== void 0 ? _b : "";
        if (key !== undefined) {
            if (groupedItems[key].length > 0) {
                var group = groupedItems[key].groupId;
                spicePack_1.groupId = group;
                // Assume that two lineitems are equal if they have the same id property
                var exists = groupedItems[key].some(function (item) {
                    return item.lineItem === spicePack_1.lineItem;
                });
                if (!exists) {
                    groupedItems[key].unshift(spicePack_1);
                }
            }
        }
    }
    return (_jsxs("div", __assign({ className: 'section' }, { children: [_jsx("div", __assign({ className: 'sectionLabelContainer' }, { children: _jsx("h2", __assign({ className: 'sectionLabel' }, { children: label })) })), isEditing ? (_jsx("div", { children: _jsx(TextField, { fullWidth: true, multiline: true, value: multilineEditValue, onChange: handleMultilineChange, onBlur: handleMultilineSubmit }) })) : (
            // Render the list of items as before if not editing
            _jsx("ul", __assign({ className: "nobulletlist" }, { children: Object.entries(groupedItems).map(function (_a) {
                    var groupId = _a[0], items = _a[1];
                    return (_jsxs(React.Fragment, { children: [(EnableGrouping && groupId && groupId.length > 0) && (_jsx("li", __assign({ className: "groupHeader" }, { children: _jsx("strong", { children: groupId || 'Ungrouped' }) }))), items.map(function (item, index) {
                                var _a, _b;
                                return (item.lineItem && _jsx("li", { children: (item.isLink && renderLinks) ? (_jsx("a", __assign({ target: "_blank", href: (_b = (_a = model === null || model === void 0 ? void 0 : model.optionalMetaData) === null || _a === void 0 ? void 0 : _a.meta_SpicePack) === null || _b === void 0 ? void 0 : _b.eCommerceProductUrl }, { children: item.lineItem }))) : (item.lineItem) }, index));
                            })] }, groupId));
                }) })))] })));
};
export default EditableMultiLineListInput;
