var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
var InlineConfirmationDialog = function (_a) {
    var open = _a.open, message = _a.message, confirmLabel = _a.confirmLabel, cancelLabel = _a.cancelLabel, onConfirm = _a.onConfirm, onCancel = _a.onCancel;
    return (_jsxs(Dialog, __assign({ open: open, onClose: onCancel, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsx(DialogContent, { children: _jsx(DialogContentText, __assign({ id: "alert-dialog-description" }, { children: message })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: onCancel, color: "primary", variant: "outlined" }, { children: cancelLabel })), _jsx(Button, __assign({ onClick: onConfirm, color: "primary", variant: "contained", autoFocus: true }, { children: confirmLabel }))] })] })));
};
// Set default prop values
InlineConfirmationDialog.defaultProps = {
    message: "Are you sure you want to proceed?",
    confirmLabel: "Yes",
    cancelLabel: "No"
};
export default InlineConfirmationDialog;
