export var Telemetry = {
    PageView: {
        UserAccount: "UserAccount",
        UserRecipes: "UserAllRecipesPage",
        UserRecipe: "UserRecipe",
        SharedRecipe: "ShareRecipe",
        SpicePackRecipe: "SpicePackRecipe",
        SpicePackDeepLink: "SpicePackDeepLink",
        SharePageDeepLink: "SharePageDeepLink",
        SpicePackHome: "SpicePackHome",
        About: "About",
        Support: "Support",
        RedirectPage: "RedirectPage",
    },
    Events: {
        UserRecipes: "UserRecipesPage",
        UserAccount: "UserAccount",
        SpicePackDeepLink: "SpicePackDeepLink",
        SpicePackHome: "SpicePackHome",
        About: "About",
        Suport: "Suport"
    },
};
