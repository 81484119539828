var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExploreIcon from '@mui/icons-material/Explore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import HelpIcon from '@mui/icons-material/Help';
import LoginIcon from '@mui/icons-material/Login';
import { NavLink } from 'react-router-dom';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import './NavigationMenuThree.css';
var NavigationMenuThree = function () {
    var _a = useAuth0(), isAuthenticated = _a.isAuthenticated, loginWithRedirect = _a.loginWithRedirect;
    var _b = useState(false), menuOpen = _b[0], setMenuOpen = _b[1];
    var _c = useState('Spice Packs'), selected = _c[0], setSelected = _c[1];
    var navContainerRef = useRef(null); // Create a ref
    var _d = useState(0), navHeight = _d[0], setNavHeight = _d[1]; // State to store the height
    useEffect(function () {
        if (navContainerRef.current) {
            setNavHeight(navContainerRef.current.offsetHeight); // Set the height when the component mounts
        }
    }, []);
    var toggleMenu = function () {
        setMenuOpen(!menuOpen);
    };
    var handleClick = function (item) {
        if (selected === item) {
            var backButton = document.getElementById('PrimaryBackButton');
            if (backButton) {
                console.log("Element with ID PrimaryBackButton exists!");
                // You can add additional logic here if needed
            }
            else {
                console.log("Element with ID PrimaryBackButton does not exist.");
            }
        }
        setSelected(item);
    };
    var NavItem = function (_a) {
        var label = _a.label, Icon = _a.Icon, onClick = _a.onClick;
        var isSelected = selected === label;
        return (_jsxs("div", __assign({ className: "nav-item".concat(isSelected ? ' nav-item-selected' : ''), onClick: function () {
                handleClick(label);
                if (onClick) {
                    onClick();
                }
            } }, { children: [_jsx(Icon, { className: "nav-icon" }), _jsx("p", { children: label })] })));
    };
    return (_jsxs("div", __assign({ className: "nav-container", ref: navContainerRef }, { children: [_jsx(Drawer, __assign({ anchor: "right", open: menuOpen, onClose: toggleMenu, PaperProps: {
                    style: {
                        height: "calc(100% - ".concat(navHeight, "px)"),
                        position: 'absolute',
                        bottom: "".concat(navHeight, "px"),
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end'
                    }
                } }, { children: _jsx("div", __assign({ className: "drawer-container" }, { children: _jsxs(List, __assign({ className: "menu-list" }, { children: [_jsx(NavLink, __assign({ className: "nav-link nav-item", to: "/about" }, { children: _jsxs(ListItem, __assign({ onClick: function () { toggleMenu(); setSelected(''); } }, { children: [_jsx(ListItemIcon, { children: _jsx(InfoIcon, {}) }), _jsx(ListItemText, { primary: "About" })] })) })), isAuthenticated && (_jsx(NavLink, __assign({ className: "nav-link nav-item", to: "/support" }, { children: _jsxs(ListItem, __assign({ onClick: function () { toggleMenu(); setSelected(''); } }, { children: [_jsx(ListItemIcon, { children: _jsx(HelpIcon, {}) }), _jsx(ListItemText, { primary: "Support" })] })) }))), isAuthenticated && (_jsx(NavLink, __assign({ className: "nav-link nav-item", to: "/profile" }, { children: _jsxs(ListItem, __assign({ onClick: function () { toggleMenu(); setSelected(''); } }, { children: [_jsx(ListItemIcon, { children: _jsx(AccountCircleIcon, {}) }), _jsx(ListItemText, { primary: "Account" })] })) })))] })) })) })), !isAuthenticated && _jsx(NavItem, { label: "Log in/Sign Up", Icon: LoginIcon, onClick: function () { loginWithRedirect(); } }), !isAuthenticated && _jsx("div", { className: "nav-divider" }), isAuthenticated && _jsx(NavLink, __assign({ id: "MyRecipesNavItem", className: "nav-link", to: "/recipes" }, { children: _jsx(NavItem, { label: "My Recipes", Icon: MenuBookIcon }) })), isAuthenticated && _jsx("div", { className: "nav-divider" }), _jsx(NavLink, __assign({ id: "SpicePacksNavItem", className: "nav-link", to: "/" }, { children: _jsx(NavItem, { label: "Spice Packs", Icon: ExploreIcon }) })), _jsxs("div", __assign({ className: "nav-item", onClick: toggleMenu }, { children: [menuOpen ? _jsx(CloseIcon, { className: "nav-icon" }) : _jsx(MenuIcon, { className: "nav-icon" }), _jsx("p", { children: "Menu" })] }))] })));
};
export default NavigationMenuThree;
