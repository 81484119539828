var mealTypes = [
    "",
    "Appetizer",
    "Beverage",
    "Breakfast",
    "Dessert",
    "Dinner",
    "Dressings",
    "Entree",
    "Lunch",
    "Salad",
    "Side",
    "Snack",
    "Soup",
    "Pet Food"
];
export default mealTypes;
