var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LoadingText from './LoadingText';
import './LoadingPage.css';
import '../Pages/styles/Logo.css';
var LoadingScreen = function (_a) {
    var children = _a.children;
    return (_jsx("div", { children: _jsxs("header", __assign({ style: { textAlign: 'center', paddingTop: '20px' } }, { children: [_jsx("img", { src: "./logo.svg", className: "logo", alt: "logo" }), _jsx("div", __assign({ className: "logoCta" }, { children: _jsx("div", { children: children || _jsx(LoadingText, {}) }) }))] })) }));
};
export default LoadingScreen;
