var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import './Layout.css';
import Header from '../Header/Header';
function Layout(props) {
    // Todo determine how I want to handle this between public/private site
    //const { isLoading } = useAuth0();
    // if (isLoading) {
    //   return <LoadingText />;
    // }
    return (_jsxs("div", __assign({ className: "Layout" }, { children: [_jsx(Header, {}), _jsx("div", __assign({ className: "main" }, { children: _jsx("div", __assign({ className: "content" }, { children: props.children })) })), _jsx("div", {})] })));
}
export default Layout;
