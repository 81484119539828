var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { HOST_Server, PATH_APICURRENTVERSION, PATH_Account } from '../../../config/config.dev';
import { Switch, FormControlLabel, Grid, Typography, Paper, Box } from "@mui/material";
import LoadingText from '../../LoadingScreen/LoadingText';
import { useAuth0 } from '@auth0/auth0-react';
export var FeaturesComponent = function () {
    var getAccessTokenSilently = useAuth0().getAccessTokenSilently;
    var _a = useState(null), features = _a[0], setFeatures = _a[1];
    var fetchFeatures = function () { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(HOST_Server).concat(PATH_APICURRENTVERSION).concat(PATH_Account, "/features"), {
                            headers: {
                                Authorization: "Bearer ".concat(accessToken),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    if (Array.isArray(data)) {
                        setFeatures(data);
                    }
                    else {
                        setFeatures([]);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var updateFeature = function (updatedFeature) { return __awaiter(void 0, void 0, void 0, function () {
        var accessToken, response, data_1, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 1:
                    accessToken = _a.sent();
                    return [4 /*yield*/, fetch("".concat(HOST_Server).concat(PATH_APICURRENTVERSION).concat(PATH_Account, "/features/").concat(updatedFeature.featureId), {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: "Bearer ".concat(accessToken),
                            },
                            body: JSON.stringify(updatedFeature),
                        })];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 3:
                    data_1 = _a.sent();
                    setFeatures(function (prevFeatures) {
                        return prevFeatures
                            ? prevFeatures.map(function (feature) {
                                return feature.featureLabel === updatedFeature.featureLabel ? data_1 : feature;
                            })
                            : [];
                    });
                    return [3 /*break*/, 5];
                case 4:
                    error_2 = _a.sent();
                    console.error(error_2);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchFeatures();
    }, []);
    var handleToggle = function (index) {
        // Create a copy of the features array
        var updatedFeatures = features ? __spreadArray([], features, true) : [];
        // Toggle the IsEnabled property of the feature at the given index
        updatedFeatures[index].isEnabled = !updatedFeatures[index].isEnabled;
        // Update the feature on the server
        updateFeature(updatedFeatures[index]);
        // Update the local state with the modified features array
        //setFeatures(updatedFeatures);
    };
    if (features === null) {
        return (_jsx(Box, __assign({ display: "flex", flexDirection: "column", alignItems: "center", p: 3 }, { children: _jsx(LoadingText, {}) })));
    }
    return (_jsx(Box, __assign({ display: "flex", flexDirection: "column", alignItems: "center", p: 3 }, { children: _jsxs(Paper, __assign({ elevation: 3, style: { borderRadius: '15px', padding: '20px', width: '300px' } }, { children: [_jsx(Typography, __assign({ variant: "h4", align: "center", gutterBottom: true }, { children: "Options" })), _jsx(Grid, __assign({ container: true, spacing: 2 }, { children: features.map(function (feature, index) { return (_jsxs(React.Fragment, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(FormControlLabel, { control: _jsx(Switch, { checked: feature.isEnabled, onChange: function () { return handleToggle(index); }, color: "primary" }), label: feature.featureLabel }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "body1", align: "center", style: { paddingLeft: '14%', fontSize: 'small' } }, { children: feature.featureDetails })) }))] }, index)); }) }))] })) })));
};
