export var servingSizes = [
    "1 cup",
    "1 c",
    "1 gram",
    "1 g",
    "1 slice",
    "1 tablespoon",
    "1 tbsp",
    "1 teaspoon",
    "1 tsp",
    "1 bowl",
    "1 piece",
    "1 pc",
    "1 ounce",
    "1 oz",
    "1 pound",
    "1 lb",
    "1 milliliter",
    "1 ml",
    "1 liter",
    "1 l",
    "1 pinch",
    "1 dash",
    "1 stick",
    "1 packet",
    "2 cups",
    "2 c",
    "2 grams",
    "2 g",
    "2 slices",
    "2 tablespoons",
    "2 tbsp",
    "2 teaspoons",
    "2 tsp",
    "2 bowls",
    "2 pieces",
    "2 pc",
    "2 ounces",
    "2 oz",
    "2 pounds",
    "2 lb",
    "2 milliliters",
    "2 ml",
    "2 liters",
    "2 l",
    "2 pinchs",
    "2 dashs",
    "2 sticks",
    "2 packets",
    "3 cups",
    "3 c",
    "3 grams",
    "3 g",
    "3 slices",
    "3 tablespoons",
    "3 tbsp",
    "3 teaspoons",
    "3 tsp",
    "3 bowls",
    "3 pieces",
    "3 pc",
    "3 ounces",
    "3 oz",
    "3 pounds",
    "3 lb",
    "3 milliliters",
    "3 ml",
    "3 liters",
    "3 l",
    "3 pinchs",
    "3 dashs",
    "3 sticks",
    "3 packets",
    "4 cups",
    "4 c",
    "4 grams",
    "4 g",
    "4 slices",
    "4 tablespoons",
    "4 tbsp",
    "4 teaspoons",
    "4 tsp",
    "4 bowls",
    "4 pieces",
    "4 pc",
    "4 ounces",
    "4 oz",
    "4 pounds",
    "4 lb",
    "4 milliliters",
    "4 ml",
    "4 liters",
    "4 l",
    "4 pinchs",
    "4 dashs",
    "4 sticks",
    "4 packets",
    "5 cups",
    "5 c",
    "5 grams",
    "5 g",
    "5 slices",
    "5 tablespoons",
    "5 tbsp",
    "5 teaspoons",
    "5 tsp",
    "5 bowls",
    "5 pieces",
    "5 pc",
    "5 ounces",
    "5 oz",
    "5 pounds",
    "5 lb",
    "5 milliliters",
    "5 ml",
    "5 liters",
    "5 l",
    "5 pinchs",
    "5 dashs",
    "5 sticks",
    "5 packets",
    "6 cups",
    "6 c",
    "6 grams",
    "6 g",
    "6 slices",
    "6 tablespoons",
    "6 tbsp",
    "6 teaspoons",
    "6 tsp",
    "6 bowls",
    "6 pieces",
    "6 pc",
    "6 ounces",
    "6 oz",
    "6 pounds",
    "6 lb",
    "6 milliliters",
    "6 ml",
    "6 liters",
    "6 l",
    "6 pinchs",
    "6 dashs",
    "6 sticks",
    "6 packets",
    "7 cups",
    "7 c",
    "7 grams",
    "7 g",
    "7 slices",
    "7 tablespoons",
    "7 tbsp",
    "7 teaspoons",
    "7 tsp",
    "7 bowls",
    "7 pieces",
    "7 pc",
    "7 ounces",
    "7 oz",
    "7 pounds",
    "7 lb",
    "7 milliliters",
    "7 ml",
    "7 liters",
    "7 l",
    "7 pinchs",
    "7 dashs",
    "7 sticks",
    "7 packets",
    "8 cups",
    "8 c",
    "8 grams",
    "8 g",
    "8 slices",
    "8 tablespoons",
    "8 tbsp",
    "8 teaspoons",
    "8 tsp",
    "8 bowls",
    "8 pieces",
    "8 pc",
    "8 ounces",
    "8 oz",
    "8 pounds",
    "8 lb",
    "8 milliliters",
    "8 ml",
    "8 liters",
    "8 l",
    "8 pinchs",
    "8 dashs",
    "8 sticks",
    "8 packets",
    "9 cups",
    "9 c",
    "9 grams",
    "9 g",
    "9 slices",
    "9 tablespoons",
    "9 tbsp",
    "9 teaspoons",
    "9 tsp",
    "9 bowls",
    "9 pieces",
    "9 pc",
    "9 ounces",
    "9 oz",
    "9 pounds",
    "9 lb",
    "9 milliliters",
    "9 ml",
    "9 liters",
    "9 l",
    "9 pinchs",
    "9 dashs",
    "9 sticks",
    "9 packets",
    "10 cups",
    "10 c",
    "10 grams",
    "10 g",
    "10 slices",
    "10 tablespoons",
    "10 tbsp",
    "10 teaspoons",
    "10 tsp",
    "10 bowls",
    "10 pieces",
    "10 pc",
    "10 ounces",
    "10 oz",
    "10 pounds",
    "10 lb",
    "10 milliliters",
    "10 ml",
    "10 liters",
    "10 l",
    "10 pinchs",
    "10 dashs",
    "10 sticks",
    "10 packets",
    "1/2 cups",
    "1/2 c",
    "1/2 grams",
    "1/2 g",
    "1/2 slices",
    "1/2 tablespoons",
    "1/2 tbsp",
    "1/2 teaspoons",
    "1/2 tsp",
    "1/2 bowls",
    "1/2 pieces",
    "1/2 pc",
    "1/2 ounces",
    "1/2 oz",
    "1/2 pounds",
    "1/2 lb",
    "1/2 milliliters",
    "1/2 ml",
    "1/2 liters",
    "1/2 l",
    "1/2 pinchs",
    "1/2 dashs",
    "1/2 sticks",
    "1/2 packets",
    "1/4 cups",
    "1/4 c",
    "1/4 grams",
    "1/4 g",
    "1/4 slices",
    "1/4 tablespoons",
    "1/4 tbsp",
    "1/4 teaspoons",
    "1/4 tsp",
    "1/4 bowls",
    "1/4 pieces",
    "1/4 pc",
    "1/4 ounces",
    "1/4 oz",
    "1/4 pounds",
    "1/4 lb",
    "1/4 milliliters",
    "1/4 ml",
    "1/4 liters",
    "1/4 l",
    "1/4 pinchs",
    "1/4 dashs",
    "1/4 sticks",
    "1/4 packets",
    "3/4 cups",
    "3/4 c",
    "3/4 grams",
    "3/4 g",
    "3/4 slices",
    "3/4 tablespoons",
    "3/4 tbsp",
    "3/4 teaspoons",
    "3/4 tsp",
    "3/4 bowls",
    "3/4 pieces",
    "3/4 pc",
    "3/4 ounces",
    "3/4 oz",
    "3/4 pounds",
    "3/4 lb",
    "3/4 milliliters",
    "3/4 ml",
    "3/4 liters",
    "3/4 l",
    "3/4 pinchs",
    "3/4 dashs",
    "3/4 sticks",
    "3/4 packets",
    "one cup",
    "one c",
    "one gram",
    "one g",
    "one slice",
    "one tablespoon",
    "one tbsp",
    "one teaspoon",
    "one tsp",
    "one bowl",
    "one piece",
    "one pc",
    "one ounce",
    "one oz",
    "one pound",
    "one lb",
    "one milliliter",
    "one ml",
    "one liter",
    "one l",
    "one pinch",
    "one dash",
    "one stick",
    "one packet",
    "two cups",
    "two c",
    "two grams",
    "two g",
    "two slices",
    "two tablespoons",
    "two tbsp",
    "two teaspoons",
    "two tsp",
    "two bowls",
    "two pieces",
    "two pc",
    "two ounces",
    "two oz",
    "two pounds",
    "two lb",
    "two milliliters",
    "two ml",
    "two liters",
    "two l",
    "two pinchs",
    "two dashs",
    "two sticks",
    "two packets",
    "three cups",
    "three c",
    "three grams",
    "three g",
    "three slices",
    "three tablespoons",
    "three tbsp",
    "three teaspoons",
    "three tsp",
    "three bowls",
    "three pieces",
    "three pc",
    "three ounces",
    "three oz",
    "three pounds",
    "three lb",
    "three milliliters",
    "three ml",
    "three liters",
    "three l",
    "three pinchs",
    "three dashs",
    "three sticks",
    "three packets",
    "four cups",
    "four c",
    "four grams",
    "four g",
    "four slices",
    "four tablespoons",
    "four tbsp",
    "four teaspoons",
    "four tsp",
    "four bowls",
    "four pieces",
    "four pc",
    "four ounces",
    "four oz",
    "four pounds",
    "four lb",
    "four milliliters",
    "four ml",
    "four liters",
    "four l",
    "four pinchs",
    "four dashs",
    "four sticks",
    "four packets",
    "five cups",
    "five c",
    "five grams",
    "five g",
    "five slices",
    "five tablespoons",
    "five tbsp",
    "five teaspoons",
    "five tsp",
    "five bowls",
    "five pieces",
    "five pc",
    "five ounces",
    "five oz",
    "five pounds",
    "five lb",
    "five milliliters",
    "five ml",
    "five liters",
    "five l",
    "five pinchs",
    "five dashs",
    "five sticks",
    "five packets",
    "six cups",
    "six c",
    "six grams",
    "six g",
    "six slices",
    "six tablespoons",
    "six tbsp",
    "six teaspoons",
    "six tsp",
    "six bowls",
    "six pieces",
    "six pc",
    "six ounces",
    "six oz",
    "six pounds",
    "six lb",
    "six milliliters",
    "six ml",
    "six liters",
    "six l",
    "six pinchs",
    "six dashs",
    "six sticks",
    "six packets",
    "seven cups",
    "seven c",
    "seven grams",
    "seven g",
    "seven slices",
    "seven tablespoons",
    "seven tbsp",
    "seven teaspoons",
    "seven tsp",
    "seven bowls",
    "seven pieces",
    "seven pc",
    "seven ounces",
    "seven oz",
    "seven pounds",
    "seven lb",
    "seven milliliters",
    "seven ml",
    "seven liters",
    "seven l",
    "seven pinchs",
    "seven dashs",
    "seven sticks",
    "seven packets",
    "eight cups",
    "eight c",
    "eight grams",
    "eight g",
    "eight slices",
    "eight tablespoons",
    "eight tbsp",
    "eight teaspoons",
    "eight tsp",
    "eight bowls",
    "eight pieces",
    "eight pc",
    "eight ounces",
    "eight oz",
    "eight pounds",
    "eight lb",
    "eight milliliters",
    "eight ml",
    "eight liters",
    "eight l",
    "eight pinchs",
    "eight dashs",
    "eight sticks",
    "eight packets",
    "nine cups",
    "nine c",
    "nine grams",
    "nine g",
    "nine slices",
    "nine tablespoons",
    "nine tbsp",
    "nine teaspoons",
    "nine tsp",
    "nine bowls",
    "nine pieces",
    "nine pc",
    "nine ounces",
    "nine oz",
    "nine pounds",
    "nine lb",
    "nine milliliters",
    "nine ml",
    "nine liters",
    "nine l",
    "nine pinchs",
    "nine dashs",
    "nine sticks",
    "nine packets",
    "ten cups",
    "ten c",
    "ten grams",
    "ten g",
    "ten slices",
    "ten tablespoons",
    "ten tbsp",
    "ten teaspoons",
    "ten tsp",
    "ten bowls",
    "ten pieces",
    "ten pc",
    "ten ounces",
    "ten oz",
    "ten pounds",
    "ten lb",
    "ten milliliters",
    "ten ml",
    "ten liters",
    "ten l",
    "ten pinchs",
    "ten dashs",
    "ten sticks",
    "ten packets",
    "half cups",
    "half c",
    "half grams",
    "half g",
    "half slices",
    "half tablespoons",
    "half tbsp",
    "half teaspoons",
    "half tsp",
    "half bowls",
    "half pieces",
    "half pc",
    "half ounces",
    "half oz",
    "half pounds",
    "half lb",
    "half milliliters",
    "half ml",
    "half liters",
    "half l",
    "half pinchs",
    "half dashs",
    "half sticks",
    "half packets",
    "quarter cups",
    "quarter c",
    "quarter grams",
    "quarter g",
    "quarter slices",
    "quarter tablespoons",
    "quarter tbsp",
    "quarter teaspoons",
    "quarter tsp",
    "quarter bowls",
    "quarter pieces",
    "quarter pc",
    "quarter ounces",
    "quarter oz",
    "quarter pounds",
    "quarter lb",
    "quarter milliliters",
    "quarter ml",
    "quarter liters",
    "quarter l",
    "quarter pinchs",
    "quarter dashs",
    "quarter sticks",
    "quarter packets"
];
