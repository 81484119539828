import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import MealGenieAccount from '../Auth/MealGenieAccount';
import Auth0Profile from '../Auth/Auth0Profile';
import Layout from '../Layouts/Layout';
import { trackPageView } from '../Telemetry/AppInsights';
import { useEffect } from 'react';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import RenderVersionInfo from "../Meta/RenderVersionInfo";
import { FeaturesComponent } from '../Auth/Account/FeaturesComponent';
import { AddressInfoComponent } from '../Auth/Account/AddressInfoComponent';
import { Telemetry } from '../Telemetry/TelemtryEventNames';
export function UserAccountPage() {
    var _a = useAuth0(), isAuthenticated = _a.isAuthenticated, isLoading = _a.isLoading;
    var renderProfileInfo = isAuthenticated ?
        _jsx("div", { children: Auth0Profile() })
        : _jsx("div", {});
    // Todo: Will render in future...
    var renderAccountInfo = isAuthenticated ?
        _jsx("div", { children: MealGenieAccount() })
        : _jsx("div", {});
    // For debugging
    var versionInfo = RenderVersionInfo();
    useEffect(function () {
        trackPageView(Telemetry.PageView.UserAccount);
    }, []);
    if (isLoading) {
        return (_jsx(Layout, { children: _jsx(LoadingScreen, {}) }));
    }
    return (_jsxs(Layout, { children: [_jsx("div", { children: renderProfileInfo }), _jsx("div", { children: _jsx(AddressInfoComponent, {}) }), _jsx("div", { children: _jsx(FeaturesComponent, {}) })] }));
}
export default UserAccountPage;
