var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// SupportComponent.tsx
import { useState, useEffect } from 'react';
import { Container, TextField, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress, Box, FormHelperText } from '@mui/material';
import { HOST_Server, PATH_APICURRENTVERSION, PATH_Support } from '../../config/config.dev';
import { trackPageView } from '../Telemetry/AppInsights';
import { Telemetry } from '../Telemetry/TelemtryEventNames';
import { useAuth0 } from '@auth0/auth0-react';
import '../Recipes/RecipeStyle.css';
import '../Pages/styles/Logo.css';
// IssueType.ts
export var IssueType;
(function (IssueType) {
    IssueType["Feedback"] = "Feedback";
    IssueType["ProductSupport"] = "Product Support";
    IssueType["AppSupport"] = "App Support";
    IssueType["CustomOrders"] = "Custom Orders";
    IssueType["Other"] = "Other";
})(IssueType || (IssueType = {}));
var SupportComponent = function () {
    var _a = useState(''), issueDetails = _a[0], setIssueDetails = _a[1];
    var _b = useState(''), issueType = _b[0], setIssueType = _b[1];
    var _c = useState(false), issueDetailsError = _c[0], setIssueDetailsError = _c[1];
    var _d = useState(false), issueTypeError = _d[0], setIssueTypeError = _d[1];
    var _e = useState(false), isLoading = _e[0], setIsLoading = _e[1];
    var _f = useAuth0(), user = _f.user, getAccessTokenSilently = _f.getAccessTokenSilently;
    var _g = useState(false), formSubmitted = _g[0], setFormSubmitted = _g[1];
    function getMessageByIssueType(issueType) {
        if (issueType === IssueType.Feedback) {
            return "What's on your mind?";
        }
        if (issueType === IssueType.CustomOrders) {
            return "Please let us know which your recipes you'd like to order from your Meal Genie cookbook, and we'll get back to you ASAP!";
        }
        return "What can we help you with?";
    }
    useEffect(function () {
        trackPageView(Telemetry.PageView.Support);
    }, []);
    var handleSubmit = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var postData, accessToken, response, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    event.preventDefault();
                    // Reset errors
                    setIssueDetailsError(false);
                    setIssueTypeError(false);
                    // Validate inputs
                    if (!issueDetails) {
                        setIssueDetailsError(true);
                    }
                    if (!issueType) {
                        setIssueTypeError(true);
                    }
                    // Stop the submission if there is an error
                    if (!issueDetails || !issueType) {
                        return [2 /*return*/];
                    }
                    setIsLoading(true);
                    postData = {
                        issueDetails: issueDetails,
                        emailContactInfo: (_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : 'undefined',
                        issueType: issueType
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, 5, 6]);
                    return [4 /*yield*/, getAccessTokenSilently()];
                case 2:
                    accessToken = _b.sent();
                    return [4 /*yield*/, fetch("".concat(HOST_Server).concat(PATH_APICURRENTVERSION).concat(PATH_Support), {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: "Bearer ".concat(accessToken)
                            },
                            body: JSON.stringify(postData)
                        })];
                case 3:
                    response = _b.sent();
                    if (!response.ok) {
                        throw new Error("HTTP error! status: ".concat(response.status));
                    }
                    // Handle success
                    setFormSubmitted(true); // Set the form submitted state to true
                    setIssueDetails('');
                    setIssueType('');
                    // Redirect after 10 seconds
                    setTimeout(function () {
                        setFormSubmitted(false); // Reset the form submitted state
                    }, 5000);
                    return [3 /*break*/, 6];
                case 4:
                    error_1 = _b.sent();
                    console.error('There was an error submitting the issue:', error_1);
                    return [3 /*break*/, 6];
                case 5:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var formContents = formSubmitted ? _jsx(Box, __assign({ component: "form", onSubmit: handleSubmit, noValidate: true, sx: { display: 'flex', flexDirection: 'column', gap: 2 } }, { children: _jsxs(Box, __assign({ sx: { textAlign: 'center' } }, { children: [_jsx("img", { src: "./logo.svg", className: "logo-support", alt: "logo" }), _jsx("h3", { children: "Thank you!" })] })) }))
        :
            _jsxs(Box, __assign({ component: "form", onSubmit: handleSubmit, noValidate: true, sx: { display: 'flex', flexDirection: 'column', gap: 2 } }, { children: [_jsxs(Box, __assign({ sx: { textAlign: 'center' } }, { children: [_jsx("img", { src: "./logo.svg", className: "logo-support", alt: "logo" }), _jsx("h3", { children: "Support" }), _jsx("p", { children: getMessageByIssueType(issueType) })] })), _jsx(TextField, { error: issueDetailsError, id: "issueDetails", label: "Details", multiline: true, rows: 4, value: issueDetails, onChange: function (e) { return setIssueDetails(e.target.value); }, helperText: issueDetailsError ? getMessageByIssueType(issueType) : "", placeholder: "Add details here", required: true, variant: "outlined", fullWidth: true }), _jsxs(FormControl, __assign({ fullWidth: true, error: issueTypeError }, { children: [_jsx(InputLabel, __assign({ id: "issueType-label" }, { children: "Issue Type" })), _jsxs(Select, __assign({ labelId: "issueType-label", id: "issueType", value: issueType, label: "Support Type", onChange: function (e) { return setIssueType(e.target.value); }, required: true }, { children: [_jsx(MenuItem, __assign({ value: "" }, { children: _jsx("em", { children: "None" }) })), Object.values(IssueType).map(function (type) { return (_jsx(MenuItem, __assign({ value: type }, { children: type }), type)); })] })), issueTypeError && (_jsx(FormHelperText, { children: "Please select an issue type." }))] })), _jsx(Button, __assign({ type: "submit", variant: "contained", color: "primary", disabled: isLoading, fullWidth: true }, { children: isLoading ? _jsx(CircularProgress, { size: 24 }) : 'Submit' }))] }));
    return (_jsx(Container, __assign({ className: "recipe-body", maxWidth: "sm", sx: { mt: 4 } }, { children: formContents })));
};
export default SupportComponent;
