var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { CONNECTION_APPINIGHTS } from '../../config/config.dev';
var appInsights = new ApplicationInsights({
    config: {
        connectionString: CONNECTION_APPINIGHTS
    }
});
appInsights.loadAppInsights();
var buildTelemetry = function (eventName, properties) {
    if (properties === void 0) { properties = {}; }
    return {
        name: eventName,
        uri: window.location.href,
        properties: __assign({}, properties)
    };
};
var trackEvent = function (eventName, properties) {
    appInsights.trackEvent({ name: eventName }, properties);
};
var trackException = function (exception, properties) {
    appInsights.trackException({ exception: exception, properties: properties });
};
var trackMetric = function (name, average, properties) {
    appInsights.trackMetric({ name: name, average: average }, properties);
};
var trackTrace = function (message, severityLevel, properties) {
    if (severityLevel === void 0) { severityLevel = SeverityLevel.Verbose; }
    appInsights.trackTrace({
        message: message,
        severityLevel: severityLevel,
        properties: properties
    });
};
var trackPageView = function (name, recipeId) {
    var telemetry = buildTelemetry(name, {
        recipeId: recipeId ? recipeId : '',
        // ... any other custom properties you want to add
    });
    appInsights.trackPageView(telemetry);
};
export { trackEvent, trackException, trackMetric, trackTrace, trackPageView };
