import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
/* Creates a popup shop buy button */
/* Example Usage:
<ShopifyBuyButton
    domain="949999a-2.myshopify.com"
    storefrontAccessToken="7d319878789389222222222333334e80"
    productId="11692222945608"
    options={ <your options here> }
    />
*/
var ShopifyBuyButton = function (_a) {
    var domain = _a.domain, storefrontAccessToken = _a.storefrontAccessToken, productId = _a.productId, options = _a.options, dontRenderUX = _a.dontRenderUX, customProductId = _a.customProductId, customProductName = _a.customProductName, ingredients = _a.ingredients;
    var defaultOptions = {
        "product": {
            "styles": {
                "product": {
                    "@media (min-width: 601px)": {
                        "max-width": "calc(25% - 20px)",
                        "margin-left": "20px",
                        "margin-bottom": "50px"
                    }
                },
                "button": {
                    "color": "#faf8fc",
                    ":hover": {
                        "color": "#faf8fc",
                        "background-color": "#51207e"
                    },
                    "background-color": "#5a248c",
                    ":focus": {
                        "background-color": "#51207e"
                    },
                    "border-radius": "8px",
                    "padding-left": "32px",
                    "padding-right": "32px"
                }
            },
            "contents": {
                "img": false,
                "button": false,
                "buttonWithQuantity": true,
                "title": false,
                "price": false
            },
            "text": {
                "button": "Add to cart"
            }
        },
        "productSet": {
            "styles": {
                "products": {
                    "@media (min-width: 601px)": {
                        "margin-left": "-20px"
                    }
                }
            }
        },
        "modalProduct": {
            "contents": {
                "img": false,
                "imgWithCarousel": true,
                "button": false,
                "buttonWithQuantity": true
            },
            "styles": {
                "product": {
                    "@media (min-width: 601px)": {
                        "max-width": "100%",
                        "margin-left": "0px",
                        "margin-bottom": "0px"
                    }
                },
                "button": {
                    "color": "#faf8fc",
                    ":hover": {
                        "color": "#faf8fc",
                        "background-color": "#51207e"
                    },
                    "background-color": "#5a248c",
                    ":focus": {
                        "background-color": "#51207e"
                    },
                    "border-radius": "8px",
                    "padding-left": "32px",
                    "padding-right": "32px"
                }
            },
            "text": {
                "button": "Add to cart"
            }
        },
        "option": {},
        "cart": {
            "styles": {
                "button": {
                    "color": "#faf8fc",
                    ":hover": {
                        "color": "#faf8fc",
                        "background-color": "#51207e"
                    },
                    "background-color": "#5a248c",
                    ":focus": {
                        "background-color": "#51207e"
                    },
                    "border-radius": "8px"
                },
                "cart": {
                    "background-color": "#faf8fc"
                },
                "footer": {
                    "background-color": "#faf8fc"
                }
            },
            "text": {
                "total": "Subtotal",
                "notice": "Free shipping in USA",
                "button": "Checkout"
            }
        },
        "toggle": {
            "styles": {
                "toggle": {
                    "background-color": "#5a248c",
                    ":hover": {
                        "background-color": "#51207e"
                    },
                    ":focus": {
                        "background-color": "#51207e"
                    }
                },
                "count": {
                    "color": "#faf8fc",
                    ":hover": {
                        "color": "#faf8fc"
                    }
                },
                "iconPath": {
                    "fill": "#faf8fc"
                }
            }
        }
    };
    // TODO Tabrock script can be loaded from disk or cdn, with disk being prefered
    useEffect(function () {
        var loadScript = function () {
            var script = document.createElement('script');
            script.async = true;
            script.id = 'shopify-buy-button-script';
            script.src = 'https://mealgeniecdn.blob.core.windows.net/scripts/buy-button-customattributes.min.js'; // saved in public folder, but have to host this due to mime type restrictions on static web app
            //script.src = 'https://mealgeniecdn.blob.core.windows.net/scripts/buybutton.exp.js'; // saved in public folder, but have to host this due to mime type restrictions on static web app
            script.type = 'text/javascript';
            script.onload = ShopifyBuyInit;
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        };
        var ShopifyBuyInit = function () {
            var client = window.ShopifyBuy.buildClient({
                domain: domain,
                storefrontAccessToken: storefrontAccessToken,
            });
            var useOpts = options !== null && options !== void 0 ? options : defaultOptions;
            if (dontRenderUX) {
                useOpts["product"]["styles"]["product"]["display"] = "None";
                useOpts["product"]["styles"]["button"]["display"] = "None";
            }
            // Stub, can be used to do certain actions when page loads
            // client.checkout.create().then((checkout: any) => { });
            window.ShopifyBuy.UI.onReady(client).then(function (ui) {
                /// Summary
                /// Custom Attribute Limits are tricky to pinpoint. 
                /// So far, I have done 100 line item keys. I have had values of 1024 chars.
                /// A safe max I have found so far is 5x1024 chars.
                var customAttributes = [];
                if (customProductName) {
                    customAttributes.push({ key: "recipe", value: customProductName });
                }
                if (customProductId) {
                    customAttributes.push({ key: "ID", value: customProductId });
                }
                if (ingredients) {
                    var count_1 = 1;
                    ingredients.forEach(function (ingredient) {
                        if (ingredient.isSpice && ingredient.lineItem) {
                            customAttributes.push({ key: "Line " + count_1.toString(), value: ingredient.lineItem });
                            count_1 += 1;
                        }
                    });
                }
                // Remove the existing component before rendering the new one
                var node = document.getElementById("product-component-".concat(productId));
                if (node) {
                    // Clear the content of the div
                    node.textContent = '';
                }
                ui.createComponent('product', {
                    id: productId,
                    node: document.getElementById("product-component-".concat(productId)),
                    moneyFormat: '%24%7B%7Bamount%7D%7D',
                    options: useOpts,
                    customAttributes: customAttributes
                });
            });
        };
        if (window.ShopifyBuy) {
            if (window.ShopifyBuy.UI) {
                ShopifyBuyInit();
            }
            else {
                loadScript();
            }
        }
        else {
            loadScript();
        }
    }, [domain, storefrontAccessToken, productId, options, ingredients]);
    return (_jsx("div", { id: "product-component-".concat(productId) }));
};
export default ShopifyBuyButton;
