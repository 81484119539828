var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingText from "../LoadingScreen/LoadingText";
import { HOST_Server, PATH_Account, PATH_APICURRENTVERSION } from "../../config/config.dev";
import { Box, Typography, Paper, Divider } from '@mui/material';
var Account = function () {
    var _a = useState(), account = _a[0], setAccount = _a[1];
    var _b = useAuth0(), user = _b.user, isAuthenticated = _b.isAuthenticated, isLoading = _b.isLoading, getAccessTokenSilently = _b.getAccessTokenSilently;
    // ... [rest of the code remains unchanged]
    var refreshInterval = 100;
    var retryCountMax = 30; // Do not exceed this many retries
    var retryCount = 0;
    var retryInterval = null;
    useEffect(function () {
        function getUserAccountInfo() {
            return __awaiter(this, void 0, void 0, function () {
                var accessToken, error_1;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            retryCount++;
                            if (retryCount >= retryCountMax) {
                                clearInterval(retryInterval);
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 5, , 6]);
                            return [4 /*yield*/, getAccessTokenSilently()];
                        case 2:
                            accessToken = _a.sent();
                            if (!accessToken) return [3 /*break*/, 4];
                            return [4 /*yield*/, fetch("".concat(HOST_Server).concat(PATH_APICURRENTVERSION).concat(PATH_Account), {
                                    headers: {
                                        Authorization: "Bearer ".concat(accessToken),
                                    },
                                }).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                                    var metadata;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, response.json()];
                                            case 1:
                                                metadata = _a.sent();
                                                if (metadata.length == 1) {
                                                    setAccount(metadata[0]);
                                                    // Stop checking once we have information
                                                    if (retryInterval) {
                                                        clearInterval(retryInterval);
                                                    }
                                                }
                                                return [2 /*return*/];
                                        }
                                    });
                                }); })];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4: return [3 /*break*/, 6];
                        case 5:
                            error_1 = _a.sent();
                            console.error(error_1);
                            return [3 /*break*/, 6];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        }
        // Check at set interval for account information.
        function retryWithDelay() {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    if (!account) {
                        retryInterval = setInterval(function () { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, getUserAccountInfo()];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        }); }); }, refreshInterval);
                    }
                    return [2 /*return*/];
                });
            });
        }
        retryWithDelay();
    }, []);
    if (isLoading) {
        return _jsx(LoadingText, {});
    }
    return (isAuthenticated && account && (_jsx(Box, __assign({ display: "flex", flexDirection: "column", alignItems: "center", p: 3 }, { children: _jsxs(Paper, __assign({ elevation: 3, style: { borderRadius: '15px', padding: '20px', width: '300px' } }, { children: [_jsx(Typography, __assign({ variant: "h4", align: "center", gutterBottom: true }, { children: "Account" })), _jsx(Divider, { style: { margin: '10px 0' } }), _jsxs(Typography, __assign({ variant: "h6" }, { children: ["Account Name: ", account.firstName, " ", account.lastName] })), _jsxs(Typography, __assign({ variant: "body1" }, { children: ["Email: ", account.email] })), _jsxs(Typography, __assign({ variant: "body1" }, { children: ["Account Disabled?: ", account.disabled ? "Yes" : "No"] })), _jsxs(Typography, __assign({ variant: "body1" }, { children: ["Details: ", account.description] })), _jsxs(Typography, __assign({ variant: "body1" }, { children: ["Member Since: ", new Date(account.createdTime).toLocaleDateString()] })), _jsxs(Typography, __assign({ variant: "body1" }, { children: ["Last Visited: ", new Date(account.lastVisitDate).toLocaleDateString()] })), _jsxs(Typography, __assign({ variant: "body1" }, { children: ["Plan: ", account.plan] })), _jsxs(Typography, __assign({ variant: "body1" }, { children: ["Owner UserId: ", account.userId] })), _jsxs(Typography, __assign({ variant: "body1" }, { children: ["Id: ", account.id] })), _jsxs(Typography, __assign({ variant: "body1" }, { children: ["Allowed Users: ", account.allowedUserIds.join(', ')] })), _jsx(Divider, { style: { margin: '10px 0' } }), _jsx(Typography, __assign({ variant: "h6" }, { children: "Notification Preferences:" }))] })) }))));
};
export default Account;
