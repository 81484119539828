var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
var Notification = function (_a) {
    var message = _a.message;
    var _b = useState(true), visible = _b[0], setVisible = _b[1];
    useEffect(function () {
        var timeoutId = setTimeout(function () {
            setVisible(false);
        }, 3000);
        return function () { return clearTimeout(timeoutId); };
    }, []);
    var handleAnimationEnd = function () {
        if (!visible && notificationRef.current) {
            // Remove the notification from the DOM after the animation finishes
            document.body.removeChild(notificationRef.current);
        }
    };
    var notificationRef = useRef(null);
    return (_jsx("div", __assign({ ref: notificationRef, style: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: '#fff7dc',
            padding: '10px',
            opacity: visible ? 1 : 0,
            transition: 'opacity 1.0s ease-in-out',
            zIndex: 10,
        }, onAnimationEnd: handleAnimationEnd }, { children: _jsx("p", __assign({ style: { textAlign: 'center', padding: '5px' } }, { children: message })) })));
};
export default Notification;
