import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
/*
React.StrictMode is a development mode tool that performs additional checks and warnings to help identify potential problems in your application.
It helps detect potential issues in your code and encourages best practices.
While it's recommended to use React.StrictMode during the development phase, it's not required for the production version of your component.
You can remove the React.StrictMode component from your component's wrapping hierarchy without affecting its functionality.
*/
// <React.StrictMode>
_jsx(App, {})
// </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
